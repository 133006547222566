@import "antd/dist/reset.css";

div.dashboard-header {
  margin-top: 2rem;
}

div.dashboard-container {
  margin-top: 2rem;
}

div.permission_card .ant-form-item {
  margin-bottom: 4px;
}

div.pageheader-user {
  padding-left: 0 !important;
}

div.site-page-header {
  padding-left: 0 !important;
}

.ant-form label {
  font-weight: 400;
}

.ant-image-preview-operations {
  background-color: #717171;
}

.table-link:hover {
  text-decoration: underline;
}
.dashboard-data-table-wrapper table p {
  display: inline;
}

body {
  font-size: 14px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

p.ant-upload-hint {
  color: #00000073;
  font-size: 14px;
}

p.red-text {
  color: red;
}

p.green-text {
  color: green;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.ant-collapse.admin-access-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0px;
}

.ant-collapse.admin-access-collapse .ant-collapse-item .ant-collapse-content ul {
  padding-left: 0px;
}

/* Fix: on IE11 menu sub navigations do not expand in horizontal mode*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ant-menu-overflow-item {
    height: 100% !important;
  }
}

div.documentedActionCard div.ant-card-body {
  padding: 15px !important;
}

li.documentedActionCard {
  padding: 5px !important;
}

div.predefinedActions {
  margin: 15px 0 15px 0;
}

div.predefinedActions .ant-flex-gap-small {
  gap: 0;
}

div.powerbi-report-container {
  height: 1000px;
}
